import { put, takeEvery, select } from "redux-saga/effects";
import {
  SagaRegistry,
  setAlertAndLoading,
  axios,
  requestHandler,
  getDateInFormat,
} from "../app";
import { ArcActions } from "./slice";
import { dateSorter } from "../common";

const cleanHTMLContent = (html) => {
  return html.replace(/&shy;/g, "").replace(/\\n/g, "");
};

const getBlueprintContentInFormat = (content) => {
  if (!content) {
    throw new Error("Content is required");
  }
  const contentTitle = content.title ? content.title.replace(/^"|"$/g, "") : "";
  const contentDescription = content.description
    ? cleanHTMLContent(content.description.replace(/^"|"$/g, ""))
    : "";
  const contentInternalNotes = content.internal_notes
    ? cleanHTMLContent(content.internal_notes.replace(/^"|"$/g, ""))
    : "";
  const tasks = Array.isArray(content.tasks) ? content.tasks : [];

  const blueprint_content = {
    blueprint_id: content.blueprint_id || "",
    title: contentTitle,
    description: contentDescription,
    internal_notes: contentInternalNotes,
    task_count: content.number_of_tasks,
    task_blueprint_id: content.id,
    created_at: getDateInFormat(content.created_at),
    status: content.status,
  };

  return blueprint_content;
};
function* createTaskBlueprint({ payload }) {
  yield requestHandler(function* () {
    const body = payload;
    let res = yield axios.post(
      "/task_blueprint/create_task_blueprint",
      body,
      axios.getFormDataContentType()
    );

    if (res) {
      // Check if there are errors in the uploaded file
      const hasErrors = res.count_not_created > 0;

      if (hasErrors) {
        // Set response with file details to show download link
        yield put(
          ArcActions.setCsvFileStatusResponse({ csvFileStatusResponse: res })
        );
        yield setAlertAndLoading(null, {
          message:
            "File processed with errors. Please download and check the error CSV.",
        });
      } else {
        // Show success message if there are no errors
        yield setAlertAndLoading(null, {
          message: "Blueprint Created Successfully.",
        });
        yield put(
          ArcActions.setCsvFileStatusResponse({ csvFileStatusResponse: res })
        );
      }
    }
  }, "Failed to create Blueprint.");
}

function* editTaskBlueprint({ payload }) {
  yield requestHandler(function* () {
    const body = payload;
    let res = yield axios.put(
      "/task_blueprint/edit_task_blueprint",
      body,
      axios.getFormDataContentType()
    );
    if ((res && !res.count_not_created) || res.count_not_created === 0) {
      yield setAlertAndLoading(null, {
        message: "Blueprint Edited Successfully.",
      });
      yield put(
        ArcActions.setCsvFileStatusResponse({ csvFileStatusResponse: res })
      );
    }
  }, "Failed to edit Blueprint.");
}
function* fetchTaskBlueprints({ payload }) {
  yield requestHandler(function* () {
    let { status } = payload;
    let path = "active_task_blueprint_list";

    switch (status) {
      case "active":
        path = "active_task_blueprint_list";
        break;
      case "archived":
      case "archive":
        path = "archive_task_blueprint_list";
        break;
    }
    let res = yield axios.get("/task_blueprint/" + path);
    res = Array.isArray(res) ? res : [];

    if (status === "active" && path === "active_task_blueprint_list") {
      res = res.filter((blueprint) => blueprint.status === "active");
    }
    if (status === "archived" && path === "archive_task_blueprint_list") {
      res = res.filter((blueprint) => blueprint.status === "archived");
    }

    res.sort(dateSorter.bind(null, "updated_at"));
    res = res.map((blueprint) => ({
      ...blueprint,
      updated_at: getDateInFormat(blueprint.updated_at),
    }));

    yield put(ArcActions.setBlueprints({ [status]: res }));
  }, "Failed to Fetch Task Blueprints List");
}
function* fetchTaskBlueprint({ payload }) {
  yield requestHandler(function* () {
    let { guid } = payload;
    let res;

    try {
      res = yield axios.post("/task_blueprint/get_task_blueprint", { guid });
      if (res) {
        const { task_blueprint, allocated_org_details, file_upload } = res;
        if (task_blueprint) {
          const blueprint_content = getBlueprintContentInFormat(task_blueprint);
          yield put(
            ArcActions.setBlueprintData({ blueprint: blueprint_content })
          );
        }
        if (Array.isArray(allocated_org_details)) {
          yield put(ArcActions.setAllocatedOrgList(allocated_org_details));
          const orgStatus = {};
          allocated_org_details.forEach((org) => {
            orgStatus[org[1]] = org[2] ? "allocated" : "deallocated";
          });
          yield put(ArcActions.setAllocationStatus(orgStatus));
        }
        if (Array.isArray(file_upload)) {
          const last_uploaded_file = file_upload[file_upload.length - 1];

          const fileName =
            last_uploaded_file?.file_upload?.document_file_name || "";
          const userName = `${last_uploaded_file?.user?.firstname || ""} ${
            last_uploaded_file?.user?.lastname || ""
          }`;
          const createdAt = last_uploaded_file?.file_upload?.created_at
            ? getDateInFormat(last_uploaded_file.file_upload.created_at)
            : "";

          const fileDetails = {
            file_name: fileName,
            user_name: userName.trim(),
            created_at: createdAt,
          };

          yield put(ArcActions.setFileUploadDetails(fileDetails));
        }
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching task blueprint:", error);
    }
  }, "Failed to fetch Task Blueprint");
}
function* archiveBlueprint({ payload }) {
  const { task_blueprint_id, status } = payload;
  let displayStatus = status === "archived" ? "archive" : "unarchive";
  yield requestHandler(function* () {
    try {
      yield put(ArcActions.setDeleteInProgress(true));
      let body = { task_blueprint_id: task_blueprint_id, status: status };
      yield axios.put("/task_blueprint/mark_as_archive", body);
      if (status === "archived" || status === "archive") {
        yield setAlertAndLoading(null, {
          message: "Task Blueprint has been Archived",
        });
        yield put(ArcActions.removeBlueprint(payload));
        yield put(ArcActions.setDeleteInProgress(false));
      } else {
        yield setAlertAndLoading(null, {
          message: "Task Blueprint has been Unarchived",
        });
        yield put(ArcActions.setDeleteInProgress(false));
      }
    } catch (error) {
      console.error(`Failed to ${displayStatus} task blueprint:`, error);
      yield put(ArcActions.setDeleteInProgress(false));
    }
  }, `Failed to ${displayStatus} task blueprint:`);
}
function* allocateBlueprint({ payload }) {
  const { ent_org_id, task_blueprint_id, is_allocated } = payload;
  yield requestHandler(function* () {
    try {
      let body = { ent_org_id, task_blueprint_id, is_allocated };
      const response = yield axios.post(
        "/task_blueprint/allocate_org_blueprint",
        body
      );
      if (response && response.allocated !== undefined) {
        yield put(
          ArcActions.setAllocationStatus({
            [response.org.id]: response.allocated ? "allocated" : "deallocated",
          })
        );
      }
      yield setAlertAndLoading(null, {
        message: `Task Blueprint has been ${
          is_allocated ? "Allocated" : "Deallocated"
        }.`,
      });
    } catch (error) {
      console.error(
        `Failed to ${is_allocated ? "allocate" : "deallocate"} task blueprint:`,
        error
      );
    }
  }, `Failed to ${is_allocated ? "allocate" : "deallocate"} task blueprint`);
}
function* fetchActiveOrgList() {
  yield requestHandler(function* () {
    let res = yield axios.get("task_blueprint/customer_list");
    yield put(ArcActions.setActiveOrgList(res));
  });
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("Arc/createTaskBlueprint", createTaskBlueprint);
  yield takeEvery("Arc/editTaskBlueprint", editTaskBlueprint);
  yield takeEvery("Arc/fetchTaskBlueprints", fetchTaskBlueprints);
  yield takeEvery("Arc/fetchTaskBlueprint", fetchTaskBlueprint);
  yield takeEvery("Arc/archiveBlueprint", archiveBlueprint);
  yield takeEvery("Arc/allocateBlueprint", allocateBlueprint);
  yield takeEvery("Arc/fetchActiveOrgList", fetchActiveOrgList);
});
